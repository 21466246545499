import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Page from '../../src/components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import '../../src/scss/react-images.scss';
import '../../src/scss/slick-slider.scss';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-W7WJ9PT');

          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '238970558318331');
          fbq('track', 'PageView');

          !(function (w, d) {
            if (!w.rdt) {
              var p = (w.rdt = function () {
                p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
              });
              p.callQueue = [];
              var t = d.createElement('script');
              (t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
              var s = d.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(t, s);
            }
          })(window, document);
          rdt('init', 't2_hrexqnjk');
          rdt('track', 'PageVisit');`}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7CJHYV5QFF"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-7CJHYV5QFF');
          gtag('config', 'AW-10827511084');

          function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };

            gtag('event', 'conversion', {
                'send_to': 'AW-10827511084/XvAWCN28gosDEKzq-qoo',
                'event_callback': callback,
            });

            gtag('event', 'conversion', {
              'event_category': 'Lead Sign Up',
              'event_action': 'Click',
            });

            return false;
          }`}
        </script>
        <meta charSet="utf-8" />
        <link rel="icon" href="/images/favicon-32x32.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>
          Livestream Video Editor | Now Create Videos Online | Catapult
        </title>
        <meta name="theme-color" content="#916bbf" />
        <meta
          name="description"
          content="Catapult is one of the best livestream video editors that helps you to turn your videos and streams into shorts, podcasts, and blogs to reach your audiences."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/og_image_1200x630.png" />
        <meta
          property="og:title"
          content="Livestream Video Editor | Now Create Videos Online | Catapult"
        />
        <meta
          property="og:description"
          content="Find the moments that matter."
        />
        <meta property="og:url" content="https://www.catapult.stream/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Page>{props.children}</Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
